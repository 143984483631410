import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '#238295',
      main: '#154E5A',
      dark: '#103A42'
    },
    secondary: {
      light: '#FFD2AE',
      main: '#FEA55B',
      dark: '#FE780B'
    },
    tertiary: {
      light: '#FFFFFF',
      main: '#F1F2F6',
      dark: '#E6E8EF'
    },
    text: {
      primary: '#212120',
      secondary: '#F0EFEB'
    },
    background: {
      default: '#F0EFEB'
    },
    divider: '#E0E0E0'
  },
  typography: {
    fontFamily: 'Inter Variable, sans-serif'
  }
});

export default theme;
