import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { createContext, useContext, useEffect, useState } from 'react';

const { VITE_FIREBASE_API_KEY } = import.meta.env;

// Firebase configuration
const firebaseConfig = {
  apiKey: VITE_FIREBASE_API_KEY,
  authDomain: 'curious-truth-440101-c6.firebaseapp.com',
  projectId: 'curious-truth-440101-c6',
  storageBucket: 'curious-truth-440101-c6.firebasestorage.app',
  messagingSenderId: '487820920968',
  appId: '1:487820920968:web:02347c778d2134bd2e773d',
  measurementId: 'G-TQQT99S7JC'
};

// Initialize Firebase app and auth
const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

// Context to provide Firebase auth state
const AuthContext = createContext<{
  auth: typeof auth;
  user: User | null;
  loading: boolean;
}>({
  auth,
  user: null,
  loading: true
});

export const useAuth = () => useContext(AuthContext);

export interface AuthProviderProps {
  children: JSX.Element | JSX.Element[];
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false); // Stop loading when auth state is determined
    });

    return () => unsubscribe();
  }, []);

  return <AuthContext.Provider value={{ auth, user, loading }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
