import GoogleIcon from '@mui/icons-material/Google';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '../../AuthProvider';
import ReactLazyLoading from '../../components/ReactLazyLoading';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { auth, loading, user } = useAuth();

  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setEmail('');
    setPassword('');
  }, [isSignUp]);

  // Handle email/password login or sign up
  const handleEmailAuth = useCallback(async () => {
    try {
      if (isSignUp) {
        await createUserWithEmailAndPassword(auth, email, password);
        console.log('User signed up with email and password');
      } else {
        await signInWithEmailAndPassword(auth, email, password);
        console.log('User logged in with email and password');
      }
    } catch (error) {
      toast.error('Authentication error');
    }
  }, [auth, email, password, isSignUp]);

  // Handle Google login
  const handleGoogleLogin = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      console.log('User signed in with Google');
    } catch (error) {
      toast.error('Google sign-in error');
    }
  }, [auth]);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [navigate, user]);

  if (loading) {
    return <ReactLazyLoading />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 4,
        maxWidth: '400px',
        margin: 'auto',
        backgroundColor: '#f7f7f7',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>
        {isSignUp ? 'Sign Up' : 'Login'}
      </Typography>

      <Box style={{ width: '100%' }}>
        <TextField
          type="email"
          label="Email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2, color: 'black' }}
          required
          slotProps={{
            input: {
              sx: {
                '&::placeholder': { color: 'black' }
              }
            },
            inputLabel: {
              sx: {
                color: 'black'
              }
            }
          }}
        />
        <TextField
          type="password"
          label="Password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mb: 2 }}
          required
          slotProps={{
            input: {
              sx: {
                '&::placeholder': { color: 'black' }
              }
            },
            inputLabel: {
              sx: {
                color: 'black'
              }
            }
          }}
        />
        <Button fullWidth variant="contained" color="primary" sx={{ mb: 2, padding: '10px' }} onClick={handleEmailAuth}>
          {isSignUp ? 'Sign Up' : 'Login'}
        </Button>
      </Box>

      <Typography sx={{ mb: 2 }}>or</Typography>

      <Button
        fullWidth
        variant="outlined"
        startIcon={<GoogleIcon />}
        onClick={handleGoogleLogin}
        sx={{
          mb: 2,
          color: '#4285F4',
          borderColor: '#4285F4',
          padding: '10px'
        }}
      >
        {`Sign ${isSignUp ? 'up' : 'in'} with Google`}
      </Button>

      <Box display="flex" flexDirection="row">
        <Typography variant="body2" sx={{ display: 'inline', paddingRight: theme.spacing(1) }}>
          {isSignUp ? 'Already have an account? ' : "Don't have an account? "}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: 'inline',
            color: 'primary.main',
            fontWeight: 'bold',
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
          onClick={() => setIsSignUp(!isSignUp)}
        >
          {isSignUp ? 'Login' : 'Sign Up'}
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
