import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function InvalidUrlRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the desired route
    navigate('/');
  }, [navigate]);

  // This component doesn't render anything
  return null;
}

export default InvalidUrlRedirect;
