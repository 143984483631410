import '@fontsource-variable/inter';
import 'leaflet/dist/leaflet.css';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './index.css';

import { lazy, StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
// import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AppAuth from './AppAuth.tsx';
import AuthProvider from './AuthProvider.tsx';
import ReactLazyLoading from './components/ReactLazyLoading.tsx';
import { APP_NAME } from './const';
import HelmetWrapper from './HelmetWrapper.tsx';
import InvalidUrlRedirect from './invalidUrlRedirect.tsx';
import theme from './theme.ts';
import Login from './pages/LoginPage/Login.tsx';

dayjs.extend(utc);
dayjs.extend(timezone);

// eslint-disable-next-line react-refresh/only-export-components
const LandingPage = lazy(() => import('./pages/LandingPage/LandingPage.tsx'));

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    {/* <Provider store={store}> */}
    <HelmetProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ToastContainer position="bottom-left" />
          <BrowserRouter>
            <AppAuth>
              <Routes>
                <Route
                  path="/"
                  element={
                    <HelmetWrapper title={APP_NAME}>
                      <Suspense fallback={<ReactLazyLoading />}>
                        <LandingPage />
                      </Suspense>
                    </HelmetWrapper>
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<InvalidUrlRedirect />} />
              </Routes>
            </AppAuth>
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </HelmetProvider>
    {/* </Provider> */}
  </StrictMode>
);
