import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import ReactLazyLoading from './components/ReactLazyLoading';

export interface AppAuthProps {
  children: JSX.Element | JSX.Element[];
}

const AppAuth = ({ children }: AppAuthProps) => {
  const { loading, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !user) {
      // Redirect to login if not authenticated
      navigate('/login');
    }
  }, [loading, user, navigate]);

  if (loading) {
    // Show loading while checking auth or redirecting
    return <ReactLazyLoading />;
  }

  return children;
};

export default AppAuth;
