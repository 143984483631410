import { Helmet } from 'react-helmet-async';

export interface HelmetWrapperProps {
  title: string;
  children: React.ReactNode;
}
const HelmetWrapper = ({ title, children }: HelmetWrapperProps) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
};

export default HelmetWrapper;
